export const mapsApiKey = "AIzaSyD4RjzGW-0DttnKQiRVwWpMs9_cqQ74u2Q"

export const firebaseConfig = {
	apiKey: "AIzaSyALfLYNdtuM_bWfiOQIHLuhLxbv7-QZ7BI",
	authDomain: "projectultrasoft.firebaseapp.com",
	projectId: "projectultrasoft",
	storageBucket: "projectultrasoft.appspot.com",
	messagingSenderId: "793182894634",
	appId: "1:793182894634:web:bb3408c783c98461cfa153",
	measurementId: "G-J5XTF7LQWE"
}